import { FC } from 'react'
import { DescriptionInfo, DescriptionTitle, Wrapper } from './styled'

type Props = {
  description?: string
}

const TechnicalSupportInfo: FC<Props> = ({ description }) => {
  if (!description) {
    return null
  }

  return (
    <Wrapper>
      <DescriptionTitle>
        Информационная и техническая поддержка
      </DescriptionTitle>
      <DescriptionInfo>{description}</DescriptionInfo>
    </Wrapper>
  )
}

export default TechnicalSupportInfo
