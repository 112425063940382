import { Collapse } from 'antd'
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react'
import { useToggle } from 'react-use'
import { useLocation } from 'react-router-dom'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { ExpandedContent, HeaderWrapper } from './styled'
import { isMobile } from '@const/device'

const { Panel } = Collapse
const ICON_SIZE = 10

type Props = {
  children: ReactNode
  title: string
  initialExpanded?: boolean
  activeIcon: ReactNode
  inactiveIcon: ReactNode
  isSmall?: boolean
  toggleIsSmall?: Dispatch<SetStateAction<boolean>>
}

const MenuAccordion: React.FC<Props> = ({
  children,
  title,
  initialExpanded = false,
  activeIcon,
  inactiveIcon,
  isSmall,
  toggleIsSmall,
}) => {
  const { pathname } = useLocation()
  const [expanded, toggleExpanded] = useToggle(initialExpanded)
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()

  const handleChange = () => {
    toggleExpanded()
    toggleIsSmall?.(false)
  }

  useEffect(() => {
    toggleExpanded(initialExpanded)
  }, [initialExpanded, pathname, toggleExpanded])

  useEffect(() => {
    if (isSmall) {
      toggleExpanded(false)
    } else {
      toggleExpanded(initialExpanded)
    }
  }, [initialExpanded, isSmall, toggleExpanded])

  return (
    <Collapse
      activeKey={expanded ? ['1'] : undefined}
      ghost
      expandIcon={() => null}
      onChange={handleChange}
    >
      <Panel
        header={
          <HeaderWrapper
            hovered={hovered}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {hovered ? activeIcon : inactiveIcon}
            {(!isSmall || isMobile) && (
              <>
                {title}
                {expanded ? (
                  <UpOutlined style={{ fontSize: ICON_SIZE }} />
                ) : (
                  <DownOutlined style={{ fontSize: ICON_SIZE }} />
                )}
              </>
            )}
          </HeaderWrapper>
        }
        key="1"
      >
        <ExpandedContent>{children}</ExpandedContent>
      </Panel>
    </Collapse>
  )
}

export default MenuAccordion
